import styled from 'styled-components'
import { vw, mq } from '@dy/commons/styles'
import { LinkStyled } from '@dy/commons/components'
import { useTranslator } from '@/hooks'
import { useRouter } from 'next/router'

const MainWrapper = styled.main`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 6;
  grid-row: 1 / span 3;
  justify-content: flex-end;
  margin-bottom:${vw(60, 'mobile')};
  width: 100%;
  z-index: 91;

  ${mq.greaterThan('nexus7')`
    margin-bottom: 107.246px;
  `}

  ${mq.greaterThan('tablet')`
      display: none;
  `}

  a, button {
    cursor: pointer;
  }

  > a {
    height: ${vw(87, 'mobile')};
    width: ${vw(260, 'mobile')};

    ${mq.greaterThan('nexus7')`
      height: 126.6px;
      width: 378px;
  `}
  }

  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    margin-bottom:${vw(30, 'mobile')};
  }
`

export const Main = () => {
  const { t } = useTranslator()
  const { locale } = useRouter()

  return (
    <MainWrapper>
      <LinkStyled href={`/pdf/corso_${locale}.pdf`} target='_blank' rel='noopener noreferrer' outline> {t('layout.main.menu')} </LinkStyled>
    </MainWrapper>
  )
}
