import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { ThemeProvider }  from 'styled-components'
import { TranslationsProvider } from '@/context'
import { ModalsProvider } from '@dy/commons/context'
import { theme, Reset } from '@dy/commons/styles'
import { Layout } from '@/components'
import Script from 'next/script'

import 'flickity/css/flickity.css'


interface iProps {
  pageProps: any
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if(!metric.label) return
  const { label, name, startTime, value } = metric

  if (label === 'web-vital' && name === 'LCP') {
    // The metric object ({ id, name, startTime, value, label }) is logged to the console
    console.info(`${label} ${name} | startTime: ${startTime} - value: ${value}`)
  }
}

const MyApp = ({ Component, pageProps: { session, ...pageProps }}: AppProps & iProps) => {
  const { translations, layout } = pageProps

  return (
    <>
      <ModalsProvider>
        <ThemeProvider theme={theme}>
          <Reset />
          <TranslationsProvider localization={translations}>
            <Layout layout={layout} >
              <Component {...pageProps}/>
            </Layout>
          </TranslationsProvider>
          <Script async src="https://www.googletagmanager.com/gtag/js?id=G-TR38X3JY89"/>
          <Script id='gtm-datalayer'  strategy='lazyOnload'>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'G-TR38X3JY89');
              `}
          </Script>
        </ThemeProvider>
      </ModalsProvider>
    </>
  )
}

//@ts-ignore
export default MyApp
