import styled from 'styled-components'
import { vw, mq, getP16, pxTorem } from '@dy/commons/styles'
import { Logo, Burger, LinkStyled, Link, Figure } from '@dy/commons/components'
import { Dropdown } from '@dy/commons/components'
import { useModal } from '@dy/commons/hooks'
import { MODALS } from '@dy/commons/context'
import { useTranslator } from '@/hooks'
import { useRouter } from 'next/router'
import { getLang } from '@dy/commons/utils'

const NavBar = styled.nav<any>`
  align-items: center;
  display: flex;
  flex-direction: row;
  grid-column: 1 / span 6;
  height:${vw(90, 'mobile')};
  justify-content: space-between;
  left: 0;
  padding:${vw(15, 'mobile')} ${vw(17, 'mobile')};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  /* MOBILE LANDSCAPE */
  @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
    height: 78px;
    padding: 15px 17px;

    > .logo {
      figure {
        height: 28px;
        width: 207px;
      }
    }

    > button {
      width: 60px;

      &,
      figure {
        height: 60px;
        width: 60px;
      }
    }
    ${mq.lessThan<any>('tablet')`
      background-color: ${({ $menuOpen, theme }) => $menuOpen ? theme.colors.darkgreen : 'transparent'};
    `}
  }


  ${mq.greaterThan('nexus7')`
    padding: 21.812px 24.720px;
  `}

  ${mq.greaterThan('tablet')`
    grid-column: 9 / span 2;
    height:${vw(78, 'desktop')};
    padding:${vw(25, 'desktop')} ${vw(50, 'desktop')};
  `}

  ${mq.greaterThan('container')`
    padding: 20.295px 40.590px;
    height: 63.32px;
  `}

`

const Wrapper = styled.div`
  display: none;

  ${mq.greaterThan('tablet')`
      align-items: center;
      display: flex;
      flex-direction: row;
  `}

  > a:not(.tiktok) {
    height: ${vw(39, 'mobile')};
    padding: ${vw(10, 'mobile')} ${vw(20, 'mobile')};
    margin-right: unset;

    ${mq.greaterThan('tablet')`
      margin-right: ${vw(20, 'desktop')};
      height:${vw(39, 'desktop')};
      padding: ${vw(10, 'desktop')} ${vw(20, 'desktop')};
    `}

    ${mq.greaterThan('container')`
      margin-right: 16.236px;
      height: 31.66px;
      padding: 8.18px 16.236px;
    `}

    div:last-of-type {
      ${getP16}
      @media screen and (min-width: 768px) and (max-width: 1170px) {
        font-size: ${pxTorem(16)};
      }
      ${mq.greaterThan('container')`
        font-size: 16px;
      `}
    }

  }
  .tiktok{
    figure {
      display: none;
      ${mq.greaterThan('tablet')`
        display:block;
        height: ${vw(28, 'desktop')};
        margin-right: ${vw(20, 'desktop')};
        width: ${vw(28, 'desktop')};
      `}
      
      ${mq.greaterThan('container')`
        display:block;
        height: 22.72px;
        margin-right: 16.2px;
        width: 22.72px;
      `}
    }
  }

`
const mediaTiktok = {
  type: 'image' as const,
  alt: 'instagram',
  default: {
    src:'/images/svg/i--tiktok.svg',
    size: { width: 24, height: 24 }
  }
}
const arrayOptions = [
  {value: 'ES', path:'/'},
  {value: 'CA', path:'/ca'},
  {value: 'EN', path:'/en'},
  {value: 'FR', path:'/fr'}
]

export const Nav = () => {
  const { isActive:isActiveModalMenu, toggle: toggleModalMenu } = useModal(MODALS.MENU)
  const { locale:routerLocale, pathname } = useRouter()
  const locale = getLang(routerLocale, pathname)

  const { t } = useTranslator()

  return (
    <NavBar $menuOpen={isActiveModalMenu}>
      <Logo name={'corso'}/>
      <Burger onClick={toggleModalMenu} menuOpen={isActiveModalMenu} />
      <Wrapper>
        <LinkStyled href={`/pdf/corso_${locale}.pdf`}target='_blank' rel='noopener noreferrer' outline>{t('layout.main.menu')}</LinkStyled>
        <LinkStyled href={`https://wa.me/c/${t('layout.footer.phone_link')}`} outline> {t('layout.main.whatsapp')}</LinkStyled>
        <Link href='https://www.tiktok.com/@iluzione_restaurant' className='tiktok'>
          <Figure media={mediaTiktok}/>
        </Link>
        <Dropdown className='detail-dropdown' trigger={locale.toUpperCase()} options={arrayOptions} direction={'down'} />
      </Wrapper>
    </NavBar>
  )
}
