import { memo } from 'react'
import styled from 'styled-components'
import { designGrid } from '@dy/commons/styles'
import { Nav } from './Nav'
import dynamic from 'next/dynamic'

const DynamicModalMenu = dynamic(() => import('../modals').then(mod => mod.ModalMenu))

const Div = styled.div`
  ${designGrid({ withoutGutter: true })}
  height: 100vh;
  overflow: hidden;
  height: 100dvh;
`

export const Layout = memo<any>(({ children }) => {
  return (
    <>
      <Div>
        <Nav />
        {children}
      </Div>
      <DynamicModalMenu />
      {/* <Susy /> */}
    </>
  )
})
