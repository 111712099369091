import { memo, useMemo } from 'react'
import type { FC } from 'react'
import type { NextPage } from 'next'
import { getTranslations } from '@/context'
import { HeadTag } from '@dy/commons/components'
import { AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { HeaderRestaurant, Footer } from '@dy/commons/components'
import { vw, mq, getP20_1, createUnderline, pxTorem } from '@dy/commons/styles'
import { useTranslator } from '@/hooks'
import { Main } from '../components'
import { getLang, getSeasonSchedule } from '@dy/commons/utils'
import { useRouter } from 'next/router'

type TypeHomePage = {
  data: any,
  translations?: any
}

export const InfoWrapper = styled.div`
  width: 98%;
  ${mq.greaterThan('tablet')`
    width: 60%;
  `}
  ${mq.greaterThan('container')`
      width:100%;
  `}
  
  p, a, span {
    ${getP20_1}
    @media screen and (min-width: 768px) and (max-width: 1170px) {
      font-size: ${pxTorem(20)};
      line-height: ${pxTorem(24)};
    }
    ${mq.greaterThan('container')`
      font-size: 20px;
      line-height: 24px;
    `}
  }

  div:nth-child(2),  div:nth-child(3){
    margin-top: ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-top: ${vw(30, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin-top: 30px;
    `}
  }

  a {
    align-items: center;
    display: flex;
    flex-direction: row;

    svg {
      display: block;
      height:${vw(24, 'mobile')};
      margin-left:${vw(5, 'mobile')};
      width:${vw(24, 'mobile')};

      ${mq.greaterThan('nexus7')`
        height: 34.9px;
        margin-left: 7.2px;
        width: 34.9px;
      `}

      ${mq.greaterThan('tablet')`
        height:${vw(24, 'desktop')};
        margin-left:${vw(5, 'desktop')};
        width:${vw(24, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        height: 24px;
        margin-left: 5px;
        width: 24px;
      `}
    }

    &:hover {
      svg {
        stroke-width:2;
        transform:translateX(${vw(5, 'mobile')});
        transition: transform 600ms ease-in-out, stroke-width 600ms ease-in-out;

        ${mq.greaterThan('tablet')`
          transform:translateX(${vw(5, 'desktop')});
        `}
      }
    }

  }

  div:nth-child(3) {
    display: block;

    ${mq.greaterThan('tablet')`
      display: none;
    `}
  }

  p > a {
    display:inline;
  }

  div:nth-of-type(2) p a {
    ${createUnderline({ color: 'white', reverse:true })}
  }
`

export async function getStaticProps({ locale }) {
  const routerLocale = getLang(locale)
  const dictionary = getTranslations(routerLocale, ['layout'])

  return {
    props: {
      data: {
        seo: {
          es:{
            title: 'Restaurante Corso ILUZIONE La Roca',
            description: 'Restaurante italiano conocido por servir también las mejores hamburguesas, convive en el mismo espacio con la exclusiva tienda de decoración Luzio.'
          },
          ca:{
            title: 'Restaurant Corso ILUZIONE La Roca',
            description: 'Restaurant italià conegut per servir també les millors hamburgueses, conviu al mateix espai amb la exclusiva botiga de decoració Luzio.'
          },
          en:{
            title: 'Corso ILUZIONE Restaurant La Roca',
            description: 'Italian restaurant also known for serving the best burgers, coexists in the same space with the exclusive decoration store Luzio.'
          },
          fr:{
            title: 'Restaurant Corso ILUZIONE La Roca',
            description: 'Restaurant italien qui est aussi connu pour servir les meilleurs hamburgers, coexiste dans le même espace avec le exclusif boutique de décotation Luzio.'
          }
        }
      },
      translations: dictionary
    }
  }
}


export const getFooterInfo = (t, season) => {
  return (
    <InfoWrapper>
      <div>
        {season === 'summer' ? <p>{t('layout.footer.weekdays_summer')}</p> :  <p>{t('layout.footer.weekdays_winter')}</p> }
        <a href='https://goo.gl/maps/ZREoJUWHu8i2b5a76'>
          <span>{t('layout.footer.direction')}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95001 4.08004L15.47 10.6C16.24 11.37 16.24 12.63 15.47 13.4L8.95001 19.92" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
      </div>
      <div>
        <span>{t('layout.footer.groups')}</span>
        <p>
          <span> {t('layout.footer.contact')}</span> <a href={`mailto:${t('layout.footer.mail')}`}>{t('layout.footer.mail')}<span></span></a>
          <span> {t('layout.footer.or')}</span> <a href={`tel:+${t('layout.footer.phone_link')}`}>{t('layout.footer.phone_number')}<span></span> </a>
        </p>
      </div>
      <div>
        <a href={`https://wa.me/c/${t('layout.footer.phone_link')}`}>
          <span>{t('layout.main.whatsapp')}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95001 4.08004L15.47 10.6C16.24 11.37 16.24 12.63 15.47 13.4L8.95001 19.92" stroke="white"strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
        <a href={'https://www.tiktok.com/@iluzione_restaurant'}>
          <span>TikTok</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.95001 4.08004L15.47 10.6C16.24 11.37 16.24 12.63 15.47 13.4L8.95001 19.92" stroke="white"strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </a>
      </div>
    </InfoWrapper>
  )
}

const HomePage: NextPage<TypeHomePage> & { Page?: FC } = memo(({ data }) => {
  const { seo } = data
  const { locale } = useRouter()
  const { t } = useTranslator()
  const seasonInfo = getSeasonSchedule('corso')
  const FooterInfo = useMemo(() => getFooterInfo(t, seasonInfo), [t, seasonInfo])
  return (
    <>
      <HeadTag seo={seo[locale]} jsonld={{ data, pageType: 'home' }}/>
      <AnimatePresence>
        <HeaderRestaurant restaurant='corso' />
        <Main/>
        <Footer web={'corso'}>
          {FooterInfo}
        </Footer>
      </AnimatePresence>
    </>
  )
})

export default HomePage
